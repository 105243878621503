.page-content {
	margin: 0 0 120px;

	@include media-xs {
		margin-bottom: 60px;
	}

	&__inner {
		@include defaultWidth;

		@include media-xs {
			padding: 0 15px;
		}
	}

	&__lead {
		text-align: center;
		margin: 0 0 70px;

		p {
			margin: 0;
		}


		@include media-xs {
			margin-bottom: 30px;
			text-align: left;
		}
	}
}
