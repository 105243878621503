.page-shop {

  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 40px;

    @include media-xs {
      margin: 0 -5px 30px;
    }
  }

  &__nav-element {
    width: calc(25% - 20px);
    margin: 0 10px 20px;

    @include media-xs {
      width: calc(100%);
      margin: 0 5px 5px;
    }
    a {
      @extend .button;
      border-radius: 3px;
      width: 100%;

      @include media-xs {
        min-width: 0;
        font-size: 11px;
      }
    }
  }

  &__heading {
    @include fs(22,15);
  }

  &__element {
    margin: 0 0 60px;

    &-inner {
      display: flex;
      justify-content: space-between;

      @include media-xs {
        display: block;
      }
    }

    &-ph {
      width: 45%;
      img {
        width: 100%;
      }

      @include media-xs {
        width: 100%;
        margin: 0 0 10px;
      }
    }

    &-info {
      width: 50%;
      line-height: 1.8;

      @include media-xs {
        width: 100%;
      }

      p {
        margin: 0;
      }
    }

    &-text {
      @include fs(14,13);
      margin-bottom: 10px;
    }

    &-table {

      @include media-xs {
        margin-bottom: 20px;
      }
      th,td {

        @include media-sm {
          padding: 5px;
        }

        @include fs(13,12);

        @include media-xs {

        }
      }

      th {
        width: 100px;
      }
    }
  }
}


//single

.page-single {

  &__title {
    @include fs(22,15);

    @include media-xs {
      margin-bottom: 10px;
    }
  }

  &__ph {
    background: #f7f4ed;
    padding-top: 30px;
    margin-bottom: 80px;

    @include media-xs {
      padding-top: 0;
      margin-bottom: 20px;
      padding: 0 15px;
      background: none;
    }

    &-element {
      text-align: center;
      width: 700px;
      margin: 0 auto;

      @include media-sm {
        transform: translate(0,30px);
      }

      @include media-xs {
        width: 100%;
      }
    }

    img {
      width: 100%;
    }
  }

  &__concept {
    line-height: 1.8;
    margin-bottom: 50px;

    @include media-xs {
      margin-bottom: 20px;
    }
  }

  &__info {
    margin-bottom: 80px;

    @include media-xs {
      margin-bottom: 40px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;

      @include media-xs {
        display: block;
      }
    }

    &-map {
      width: 48%;

      @include media-xs {
        width: 100%;
        margin-bottom: 20px;
      }

      iframe {
        width: 100%;
        height: 350px;

        @include media-xs {
          height: 240px;
        }
      }
    }

    &-element {
      width: 48%;

      @include media-xs {
        width: 100%;
      }
    }
  }

  &__menu {
    margin-bottom: 80px;

    @include media-xs {
      margin-bottom: 40px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include media-xs {
        display: block;
      }
    }

    &-element {
      width: 48%;
      display: flex;
      margin-bottom: 20px;
      flex-wrap: wrap;
      justify-content: space-between;

      @include media-xs {
        width: 100%;
      }
    }

    &-name {
      font-weight: bold;
      width: calc(100% - 80px);
    }

    &-price {
      color: #c8a378;
      font-family: $font-family-yumin;
      width: 70px;
      text-align: right;
    }

    &-text {
      margin-top: 10px;
      @include fs(14,13);
    }
  }
}
