.form {

	&__input {
		display: block;
		width: 100%;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	}

	&__textarea {
		display: block;
		width: 100%;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		height: 200px;
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	}

	&__label-req {
		width: 37px;
		@include fs(11,10);
		display: inline-block;
		background: #e23c3c;
		color: #FFF;
		font-weight: normal;
		text-align: center;
		line-height: 1;
		padding: 5px 0;
		border-radius: 3px;
		vertical-align: middle;
		margin-left: 10px;
		position: absolute;
		right: 10px;
		@include centeringElements(false,true);
	}

	&__table-input {
		text-align: center;
		margin-top: 40px;

		@include media-xs {
			margin-top: 20px;
		}
	}
}
