.heading-l {
  text-align: center;
  margin-bottom: 30px;

  @include media-xs {
    margin-bottom: 15px;
  }

  span {
    font-family: $ac-font-family;
    font-weight: 500;
    display: block;
    letter-spacing: .04em;
    @include fs(45,28);
  }

  small {
    display: block;
    color: #bec9c4;
    @include fs(15,11);
  }
}

.heading-m {
  font-family: $font-family-yumin;
  font-weight: 600;
  @include fs(28,18);
  margin-bottom: 40px;

  span {
    border-bottom: 1px solid #bec9c4;
    padding-bottom: 20px;
  }
}

.heading-s {
  color: $base-color;
  @include fs(18,14);
  font-weight: bold;
  margin: 0 0 20px;
  border-left: 2px solid $base-color;
  padding-left: 10px;

}

.heading-ss {

}

.heading-xs {

}

.heading-sss {

}
