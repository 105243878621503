$font-family-hiragino: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-yugo:  '游ゴシック', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-meiryo: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-yumin: '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', HiraMinProN-W3, HGS明朝E, 'ＭＳ Ｐ明朝', 'MS PMincho', serif;

@import url('//fonts.googleapis.com/css?family=Montserrat:400,500,600');

@font-face {
  font-family: 'Playlist-Script';
    src:  url('./fonts/Playlist-Script.ttf.woff') format('woff'),
    url('./fonts/Playlist-Script.ttf.svg#Playlist-Script') format('svg'),
    url('./fonts/Playlist-Script.ttf.eot'),
    url('./fonts/Playlist-Script.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

$base-font-family: $font-family-yugo;
$sub-font-family: 'Montserrat', sans-serif;
$ac-font-family: 'Playlist-Script' ,sans-serif;


$root-font-size: 62.5%; // 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5%
$base-font-size: 1.5rem; // 16pt base
$base-line-height: 1.7;
