.align-left {
   text-align: left!important;
}
.align-center {
   text-align: center!important;
}
.align-right {
   text-align: right!important;
}

.w100 {
	width: 100%!important;
}

//margin
@for $i from -20 through 30 {
	.mt#{5*$i} {
		margin-top: #{5*$i}px!important;
	}
}

@for $i from 0 through 30 {
	.ml#{5*$i} {
		margin-left: #{5*$i}px!important;
	}
}

@for $i from 0 through 30 {
	.mr#{5*$i} {
		margin-right: #{5*$i}px!important;
	}
}

@for $i from 0 through 30 {
	.mb#{5*$i} {
		margin-bottom: #{5*$i}px!important;
	}
}

@include media-xs {
	@for $i from -20 through 30 {
		.s-mt#{5*$i} {
			margin-top: #{5*$i}px!important;
		}
	}

	@for $i from 0 through 30 {
		.s-ml#{5*$i} {
			margin-left: #{5*$i}px!important;
		}
	}

	@for $i from 0 through 30 {
		.s-mr#{5*$i} {
			margin-right: #{5*$i}px!important;
		}
	}

	@for $i from 0 through 30 {
		.s-mb#{5*$i} {
			margin-bottom: #{5*$i}px!important;
		}
	}
}

.row-0{
	margin-left:0px;
	margin-right:0px;
	> div{
		padding-right:0px;
		padding-left:0px;
	}
}
.row-10{
	margin-left:-5px;
	margin-right:-5px;
	> div{
		padding-right:5px;
		padding-left:5px;
	}
}
.row-20{
	margin-left:-10px;
	margin-right:-10px;
	> div{
		padding-right:10px;
		padding-left:10px;
	}
}
.row-30{
	margin-left:-15px;
	margin-right:-15px;
	> div{
		padding-right:15px;
		padding-left:15px;
	}
}
.row-40{
	margin-left:-20px;
	margin-right:-20px;
	> div{
		padding-right:20px;
		padding-left:20px;
	}
}
