.home-hero {
  height: 85vh;
  overflow: hidden;
  position: relative;

  @include media-xs {
    height: 450px;
  }

  &__inner {
    width: 100%;
    height: 100%;
  }

  .slick-track {
    // height: 85vh;
  }

  .slick-next {
    right: 20px;
    top: auto;
    bottom: 20px;
    background: url(../img/index/icon_right.png) no-repeat;
    @include bgs(9px,14px);
    text-indent: -9999px;

    @include media-xs {
      right: 10px;
      bottom: 3px;
    }
  }

  .slick-prev {
    left: auto;
    right: 41px;
    top: auto;
    bottom: 20px;
    z-index: 20;
    background: url(../img/index/icon_left.png) no-repeat;
    @include bgs(9px,14px);
    text-indent: -9999px;

    @include media-xs {
      right: 28px;
      bottom: 3px;
    }
  }

  &__slider {
    width: 100%;
    height: 100%;

    &-element {
      width: 100vw;
      height: 85vh;
      background-size: cover;
      background-position: center center;

      @include media-xs {
        height: 450px;
      }
    }

    &-element--01 {
      background-image: url(../img/index/img-slide01.jpg);
    }

    &-element--02 {
      background-image: url(../img/index/img-slide02.jpg);
    }

    &-element--03 {
      background-image: url(../img/index/img-slide03.jpg);
    }
  }

  &__text {
    width: 557px;
    background: rgba(#000,.75);
    color: #FFF;
    position: absolute;
    right: 0;
    z-index: 70;
    bottom: 100px;
    padding: 0 50px 20px;

    @include media-xs {
      width: 300px;
      padding: 0 20px 18px;
      right: auto;
      left: 0;
      bottom: 30px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 174px;
      background: url(../img/shared/bg_title-page-bottom.png);
      position: absolute;
      top: 17px;
      left: 10px;

      @include media-xs {
        height: 100px;
        top: 5px;
        left: 5px;
      }
    }

    &-inner {
      position: relative;
      z-index: 1;
    }

    &-title {
      @include fs(78,39);
      font-family: $ac-font-family;
      line-height: 1;
      transform: translate(0,-25px);

      @include media-xs {
        transform: translate(0,-15px);
      }
    }

    p {
      @include fs(17,10);
      margin-top: -5px;
      line-height: 1.9;
    }
  }
}

.page-home {

  &__about {
    position: relative;
    padding-top: 80px;
    margin-bottom: 80px;

    @include media-xs {
      padding-top: 30px;
      margin-bottom: 30px;
    }

    p {
      line-height: 2;
      margin-bottom: 30px;

      @include media-xs {
        margin-top: 0;
        br {
          display: none;
        }
      }
    }

    &::before {
      content: "";
      width: calc(67%);
      height: 360px;
      position: absolute;
      display: block;
      background: #ece6d7;
      z-index: -1;
      top: 0;
      right: 0;

      @include media-xs {
        height: 320px;
        width: 60%;
      }
    }

    &::after {
      content: "";
      width: calc(100% - 365px);
      height: 450px;
      // position: absolute;
      background: url(../img/index/img_index-ph-02.jpg) no-repeat;
      background-size: cover;
      background-position: center center;
      display: block;
      margin-top: 90px;
      z-index: -1;

      @include media-xs {
        width: 100%;
        height: 140px;
      }
    }
    .heading-l {
      text-align: left;
      margin-top: 0;
    }

    .button-block {
      justify-content: flex-start;

      .button {
        margin: 0;
      }
    }

    &-inner {
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 408px;
        height: 535px;
        background: url(../img/index/img_index-ph-01.jpg) no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;

        @include media-xs {
          width: 130px;
          height: 170px;
          top: auto;
          bottom: -160px;
        }
      }
    }
  }

  &__shop {

    margin-bottom: 90px;

    @include media-xs {
      margin-bottom: 40px;
    }

    &-list {
      display: flex;
      margin-left: -1px;

      @include media-xs {
        display: block;
      }
      margin: 0;
    }

    &-element {
      width: 25%;
      margin-left: 1px;
      text-align: center;

      @include media-xs {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 15px;
      }
    }

    &-ph {
      margin-bottom: 15px;

      @include media-xs {
        margin-bottom: 8px;
      }
      img {
        width: 100%;
      }
    }

    &-title {
      font-weight: bold;
      margin-bottom: 15px;

      @include media-xs {
        margin-bottom: 8px;
      }
    }

    &::after {
      content: "";
      height: 480px;
      display: block;
      width: 100%;
      background: url(../img/index/bg_top-section.jpg) no-repeat center top;
      background-size: cover;
      margin-top: 100px;

      @include media-xs {
        height: 0;
        padding-top: calc(480 / 1400 * 100%);
        margin-top: 30px;
      }
    }
  }

  &__news {
    margin-bottom: 100px;

    @include media-xs {
      margin-bottom: 40px;
    }

    &-list {
      margin: 0 0 40px;

      @include media-xs {
        margin-bottom: 15px;
      }
    }

    &-element {
      margin-bottom: 5px;
    }

    &-element a {
      display: block;
      border: 1px solid $base-color;
      line-height: 1;
      padding: 18px 35px 16px;
      text-decoration: none;
      color: $base-font-color;
      transition: .2s linear;

      @include media-xs {
        padding: 13px 15px;
      }

      time {
        font-family: $sub-font-family;

        @include media-xs {
          display: block;
          margin-bottom: 5px;
        }

        &::after {
          content: "›";
          color: $base-color;
          display: inline-block;
          margin: 0 10px;
          vertical-align: text-top;
          transition: .2s linear;

          @include media-xs {
            display: none;
          }
        }
      }

      span {
        @include media-xs {
          line-height: 1.5;
        }
      }


      &:hover {
        background: $base-color;
        color: #FFF;

        time::after {
          color: #FFF;
        }
      }
    }
  }

  &__recruit {
    background: url(../img/index/bg_recruit.jpg) no-repeat center center;
    background-size: cover;
    color: #FFF;
    padding: 90px 0 90px;

    @include media-xs {
      padding: 30px 0 35px;
    }

    @include media-xs {
      background-image: url(../img/index/bg_retruit-xs.jpg);
    }

    &-inner {
      display: flex;
      justify-content: flex-end;
      width: 1140px;
      margin: 0 auto;

      @include media-xs {
        width: 100%;
        display: block;
      }
    }

    &-info {

    }

    .button {
      border-color: #FFF;
      color: #FFF;
      background: transparent;

      &:hover {
        background: #FFF;
        color: $base-color;
      }
    }

    .heading-l {
      text-align: left;
      span {
        color: #FFF;
        text-align: left;
      }

      @include media-xs {
        text-align: center;

        span {
          text-align: center;
        }
      }
    }

    .button-block {
      justify-content: flex-end;

      @include media-xs {
        justify-content: center;
      }
      .button {
        margin: 0;
      }
    }

    &-text {
      margin-bottom: 25px;

      @include media-xs {
        text-align: center;
      }
    }
  }
}
