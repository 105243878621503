.page-recruit {

  &__head {
    font-family: $font-family-yumin;
    @include fs(17,16);
    text-align: center;
  }
  &__lead {
    @include fs(14,13);
    font-family: $font-family-yumin;
    line-height: 2;
    position: relative;
    margin: 0;
    background: #f7f4ed;
    margin-bottom: 100px;
    padding: 25px 0 0;

    @include media-xs {
      padding: 20px 0;
      margin-bottom: 40px;
    }

    P {
      width: 50%;

      @include media-xs {
        width: 100%;
        text-align: left;
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-xs {
        display: block;
      }
    }


  }

  &__ph {
    height: 380px;
    width: 45%;
    background: url(../img/index/bg_top-section.jpg) no-repeat center center;
    background-size: cover;

    @include media-xs {
      width: 100%;
      height: 200px;
    }

    @include media-sm {
      transform: translate(0,30px);
    }
  }

  &__table {
    margin-bottom: 60px;

    @include media-xs {
      margin-bottom: 30px;
    }

  	@include media-xs {
  		width: 100%;
  	}

  	th {
  		width: 200px;
  	}
  }
}
