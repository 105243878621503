.title-page {
	text-align: center;
	color: #FFF;
	background: url(../img/shared/bg_title-page.jpg) no-repeat center center;
	background-size: cover;
	height: 340px;
	position: relative;
	margin: 0;

	&::after {
		content: "";
		display: block;
		width: 100%;
		background: url(../img/shared/bg_title-page-bottom.png) repeat-x;
		height: 20px;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@include media-sm {
		// background-attachment: fixed;
	}

	@include media-xs {
		height: 130px;
	}

	&__heading {
		@include centeringElements;
		margin: 0;

		&-main {
			display: block;
			font-size: 6rem;
			font-family: $ac-font-family;
			font-weight: normal;
			margin-top: 40px;
			letter-spacing: .04em;

			@include media-xs {
				font-size: 2.8rem;
				padding: 0 10px;
				margin-top: 35px;
			}
		}

		&-sub {
			display: block;
			font-size: 1.5rem;
			font-weight: normal;
			color: rgba(#fff,.9);
			letter-spacing: .1em;
			@include media-xs {
				line-height: 1;
				font-size: 1.1rem;
			}
		}
	}
}
