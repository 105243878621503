.button-block {
	display: flex;
	text-align: center;
	justify-content: center;

	.button,.button-em {
		@include media-sm {
			margin: 0 20px;
		}
	}
}

.button {
	@include buttonReset;
	text-align: center;
	min-width: 160px;
	background: #00572f;
	color: #FFF;
	font-family: $sub-font-family;
	@include fs(13,11);
	padding: 10px 10px;
	line-height: 1.4;
	border: 1px solid transparent;
	transition: .2s linear;
	display: inline-block;

	&:hover {
		color: #00572f;
		background: transparent;
		border-color: #00572f
	}
}

.button-em {
	@extend .button;
	color: #00572f;
	background: transparent;
	border-color: #00572f;

	&:hover {
		background: #00572f;
		color: #FFF;
	}

}
