$smallBreak: 767px;
$mediumBreak: 768px;


@mixin media-xs() {
  @media screen and (max-width: $smallBreak) {
    @content;
  }
}

@mixin media-sm() {
  @media screen and (min-width: $mediumBreak) {
    @content;
  }
}
