.breadcrumb {
	margin: 0 0 70px;
	padding: 10px 0;
	position: relative;
	height: 36px;
	text-align: right;

	@include media-xs {
		height: 28px;
		padding: 8px 15px;
		margin-bottom: 30px;
	}

	&::after {
		content: "";
		display: block;
		height: 36px;
		background: #ece6d7;
		width: 70%;
		position: absolute;
		right: 0;
		top: 0;

		@include media-xs {
			width: 90%;
			height: 28px;
		}
	}

	&__body {
		@include defaultWidth;
	}

	ol {
		display: flex;
		justify-content: flex-end;
		list-style: none;
		margin: 0;
		position: relative;
		z-index: 2;
		padding: 0;
	}

	&__item {
		line-height: 1;
		font-size: 1.2rem;
		font-family: $sub-font-family;

		&::after {
			content: "›";
			font-family: $base-font-family;
			display: inline-block;
			margin: 0 12px;

			@include media-xs {
				margin: 0 6px;
			}
		}

		&:last-child {
			&::after {
				display: none;
			}
		}

		@include media-xs {
			font-size: 1rem;
		}

		a {
			color: $base-font-color;
			text-decoration: none;


			&:hover {
				text-decoration: none;
			}

			span {
				border-bottom: 1px solid #76736b;
			}
		}
	}
}
