.page-company {

  &__lead {
    margin-top: -100px;
    text-align: center;
    background: url(../img/company/bg_company-lead.png) no-repeat center 0px;
    padding: 120px 0 0;
    margin-bottom: 100px;

    @include media-xs {
      margin-bottom: 50px;
      background-size: 140% auto;
      margin-top: -120px;
    }

    &::after {
      content: "";
      height: 370px;
      width: 100%;
      background: url(../img/company/bg_company-section.jpg) repeat-x center top;
      display: block;

      @include media-xs {
        width: 100%;
        background-size: 100% auto;
        height: 0;
        padding-top: 24.62%;
      }
    }

    p {
      @include fs(28,16);
      font-family: $font-family-yumin;
      line-height: 1.8;
      position: relative;
      margin: 0;
      padding-bottom: 90px;

      @include media-xs {
        padding-bottom: 40px;
      }

      &::after {
        content: "";
        background: #FFF;
        width: 1000px;
        height: 50px;
        bottom: -50px;
        display: block;
        position: absolute;
        @include centeringElements(true,false);

        @include media-xs {
          display: none;
        }
      }
    }
  }

  &__about {
    margin-bottom: 100px;

    @include media-xs {
      margin-bottom: 50px;
    }

    &-table {
      display: flex;
      justify-content: space-between;

      @include media-xs {
        display: block;
      }

      table {
        width: 47%;

        @include media-xs {
          width: 100%;
          margin-bottom: 0;

          + table {
            border-top: 0;
          }
        }
      }
    }
  }

  &__history {
    background: #f7f4ed;
    padding-top: 60px;

    @include media-xs {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;

      @include media-xs {
        display: block;
      }
    }

    time {
      margin-right: 10px;
    }

    &-ph {
      sm {
        transform: translate(0,30px);
      }

      @include media-xs {
        img {
          width: 100%;
        }
      }
    }

    &-detail {
      @include fs(15,13);
      &-element {
        margin-bottom: 7px;

        @include media-xs {
          margin-bottom: 3px;
        }
      }
    }
  }
}
