table,.table {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
	margin: 0 0 40px;

	@include media-xs {
		border-top: 1px solid $base-color;
	}

	th,td {
		// border: 1px solid $sub-color;
		text-align: left;
		padding: 18px 10px 12px;
		position: relative;
		vertical-align: top;
		@include media-xs {
			display: block;
			margin-top: -1px;
			width: 100% !important; // stylelint-disable-line declaration-no-important
			padding-right: 0;
			padding-left: 0;
		}
	}

	th {
		color: $base-color;
		border-bottom: 2px solid $base-color;
		width: 160px;

		@include media-xs {
			border-bottom: 5px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	td {
		border-bottom: 1px solid #bec8c4;

		@include media-xs {
			border-bottom: 1px solid $base-color;
			padding-top: 0;
			padding-bottom: 5px;
		}
	}
}


.form__table {
	width: 750px;
	margin: 0 auto;

	@include media-xs {
		width: 100%;
	}

	th {
		width: 200px;
	}
}
