//====================================================
//  Mixin
//====================================================

@mixin defaultWidth {
	@include media-sm {
		width: $base-width;
		margin: 0 auto;
	}
}

@mixin noHover {
	&:hover {
		img {
			@include opacity(1);
		}
	}
}

@mixin textIn {
	text-indent: -9999px;
	display: block;
}

@mixin hoverAction($opacity:0.7,$time:0.15s) {
	&:hover {
		transition: $time opacity linear;
		@include opacity($opacity);
	}
}


@mixin bgs($width,$height) {
	width: $width;
	height: $height;
	background-size: $width $height;
}


 @mixin iconFont($icon-name, $place: before) {
	&:#{$place} {
		content: $icon-name;
		font-family: FontAwesome;
		@content;
	}
}

@mixin truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin buttonReset {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
	text-decoration: none;
}

@mixin centeringElements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin fs($sm-font-size,$xs-font-size) {
	font-size: #{$sm-font-size / 10 }rem;

	@include media-xs {
		font-size: #{$xs-font-size / 10 }rem;
	}
}

/* Flashing */

@mixin hoverFlash {
	&:hover img {
		opacity: 1;
		animation: flash 1.5s;
	}
	@keyframes flash {
		0% {
			opacity: .4;
		}
		100% {
			opacity: 1;
		}
	}
}
