.accordion-block {
	&__element {
		margin-bottom: 30px;
	}

	&__title {
		font-size: 20px;
		background: url(../img/bg_ac_ttl.png);
		padding: 10px 20px;
		cursor: pointer;
		margin-bottom: 5px;
		position: relative;

		&.faq-q {
			padding-left: 70px;
			i.faq-ico {

				font-size: 32px;
				position: absolute;
				line-height: 1;
				left: 20px;
				top: 10px;
				font-style: normal;
			}
			@include media-xs {
				padding-left: 50px;
				i.faq-ico {
					font-size: 24px;
					left: 13px;
					top: 10px;
				}
			}

		}
		@include media-xs {
			padding: 10px 10px 12px;
			font-size: 15px;
			line-height: 1.5;
			margin-bottom: 0;
		}
	}
	&-ttl-inner {
		position: relative;
		display: block;
		padding-right: 35px;
		@include media-xs {
			padding-right: 35px;
		}
		&::after,&::before {
			content: "";
			display: block;
			right: 0;
			height: 4px;
			top: 18px;
			width: 26px;
			background: #000;
			position: absolute;
			transition: all 0.6s;
			transform: rotate(0deg);
			z-index: 90;
			@include media-xs {
				height: 3px;
				width: 15px;
				right: 0;
				top: 10px;
			}
		}
		&::before {
			transform: rotate(90deg);
		}
		&.active {
			&::after {
				transform: rotate(180deg);
			}
			&::before {
				transform: rotate(0deg);
			}
		}
	}
	&-ttl[aria-expanded="true"] {
		.accordion-block-ttl-inner {
			&::after {
				transform: rotate(180deg);
			}
			&::before {
				transform: rotate(0deg);
			}
		}
	}
	&-contents {
		padding: 10px 20px;
		display: none;
		&.faq-a {
			padding-left: 70px;
			position: relative;
			i.faq-ico {
				content: "A";
				font-size: 32px;
				position: absolute;
				font-style: normal;
				line-height: 1;
				left: 23px;
				top: 16px;
				color: #ccc;
			}
			@include media-xs {
				padding-left: 50px;
				i.faq-ico {
					font-size: 24px;
					left: 15px;
				}
			}
		}
		@include media-xs {
			padding: 5px 0;
			font-size: 14px;
		}
	}
}
