.nav-global {

	@include media-xs {
		position: fixed;
		z-index: 10;
		width: 100%;
		display: none;
		background: rgba(#0e221a,.95);
		left: 0;
		top: 0;
		height: 100vh;
		padding-top: 150px;
	}

	&__body {
		// @include defaultWidth;
	}

	&__list {
		display: flex;
		justify-content: space-between;

		@include media-xs {
			display: block;
			text-align: center;
		}
	}

	&__item {
		margin-left: 72px;

		@include media-xs {
			margin-left: 0;
		}

		> a {
			display: block;
			padding: 10px 5px;
			color: #FFF;
			text-decoration: none;
			font-family: $sub-font-family;
			@include fs(15,12);

			&::after {
							position: absolute;
						  bottom: -4px;
						  left: 0;
						  content: '';
						  width: 100%;
						  height: 2px;
						  background: #FFF;
						  transform: scale(0, 1);
						  transform-origin: right top;
						  transition: transform .3s;
						}

						span {
							position: relative;
						  display: inline-block;
						  text-decoration: none;

							&::after {
								position: absolute;
							  bottom: -4px;
							  left: 0;
							  content: '';
							  width: 100%;
							  height: 2px;
							  background: #FFF;
							  transform: scale(0, 1);
							  transform-origin: right top;
							  transition: transform .3s;
							}
						}

						@include media-sm {
							&:hover span::after {
								transform-origin: left top;
							  transform: scale(1, 1);
							}
						}
		}

		@include media-xs {
			a {
					display: block;
					padding: 10px 15px;
					font-size: 1.3rem;
			}
		}
	}
}
