.page-news {

  &__element {
    border-bottom: 1px solid #bec9c4;
    padding-bottom: 40px;
    margin-bottom: 40px;

    @include media-xs {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &__header {
    margin: 0 0 20px;

    @include media-xs {
      margin-bottom: 10px;
    }

    time {
      @include fs(13,12);
      font-weight: bold;
      color: $base-color;
      margin-bottom: 8px;
      display: block;
    }
  }
}
