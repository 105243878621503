.global-footer {
	background: #131615;
	text-align: center;
	padding-bottom: 50px;

	@include media-xs {
		padding-bottom: 20px;
	}

	&__body {
		@include defaultWidth;
		padding: 40px 0 20px;

		@include media-xs {
			padding: 25px 0 0;
			display: block;
		}
	}

	&__logo {
		margin-bottom: 25px;

		@include media-xs {
			img {
				width: 82px;
			}
		}
	}

	&__copyright {
		text-align: center;
		color: #FFF;
		@include fs(12,10);
		font-family: $sub-font-family;
		letter-spacing: .02em;

		@include media-xs {
			font-size: 1rem;

		}
	}

	&__links {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;

		@include media-xs {
			flex-wrap: wrap;
			margin: 0 0 20px;
			border-top: 1px solid #242826;
		}

		a {
				text-decoration: none;
				color: #FFF;
				font-family: $sub-font-family;
				font-weight: normal;
				@include fs(12,10);
				letter-spacing: 0.01em;
				line-height: 1;

				@include media-xs {
					display: block;
					padding: 14px 15px;
					border-left: 1px solid #242826;
					border-bottom: 1px solid #242826;
				}
		}

		&-element {
			margin: 0 10px;

			&::after {
				content: "|";
				color: #FFF;
				margin: 0px;
				margin-left: 15px;
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}

			@include media-xs {
				width: 50%;
				margin-left: 0;
				margin: 0;
				&::after {
					display: none;
				}

				&:nth-child(odd) a {
					border-left: 0;
				}
			}
		}
	}

	&__address {
		@include media-xs {
			padding: 0 15px;
		}
	}
}
