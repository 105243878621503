.global-header {
	background: rgba(#0d0e0d,.6);
	position: fixed;
	z-index: 100;
	width: 100%;
	left: 0;
	top: 0;

	height: 90px;

	@include media-xs {
		height: 59px;
	}

	&__body {
		&-upper {
			padding: 15px 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media-xs {
				padding: 5px 15px;
				display: block;
			}
		}
	}

	&__contact {

	}

	&__title {
		@include media-xs {
			display: inline-block;
		}
	}

	&__site-name {
		margin: 0;
		font-size: 1rem;



		a {
			display: block;
			text-indent: -9999px;
			background: url(../img/shared/logo.png) no-repeat;
			@include bgs(96px,60px);
			// @include bgs(190px,120px);

			@include media-xs {
				@include bgs(72px,45px);
			}
		}

		@include media-xs {
			img {
				width: 50%;
			}
		}
	}

	&__sp-menu {
		position: absolute;
		right: 15px;
		top: 20px;
		z-index: 2220;

		&--open {
			opacity: 1;
		}

		&--close {
			opacity: 0;
		}


		> button {
			@include buttonReset;
			width: 31px;
			height: 32px;
			display: block;

			&.active .global-header__sp-menu--open {
				opacity: 0;
			}

			&.active {
				.global-header__sp-menu--close {
					opacity: 1;
				}
			}

			> small {
				transition: .2s linear;
				font-weight: bold;
				font-size: 1rem;
				color: #FFF;
				position: absolute;
				bottom: 0;
				width: 100%;
				display: block;
				text-align: center;
				font-family: $sub-font-family;
				transform: scale(.8);
				margin-top: -2px;
			}


			// stylelint-disable
			&.active {
				position: fixed;
				right: 15px;

				span:nth-of-type(1) {
					transform: translateY(0) rotate(-45deg);
				}

				span:nth-of-type(2) {
					left: 60%;
					opacity: 0;
				}

				span:nth-of-type(3) {
					transform: translateY(7px) rotate(45deg);
				}
			}
			// stylelint-enable
			span {
				background-color: #FFF;
				box-sizing: border-box;
				display: inline-block;
				height: 2px;
				left: 5px;
				border-radius: 4px;
				position: absolute;
				transition: all .4s;
				width: 22px;

				&:nth-of-type(1) {
					top: 7px;
				}

				&:nth-of-type(2) {
					top: 14px;
				}

				&:nth-of-type(3) {
					top: 0;
				}
			}
		}
	}
}
