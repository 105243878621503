.pagination {
	clear: both;
	display: flex;
	font-size: 1.5rem;
	justify-content: center;
	margin: 20px 0 90px;
	overflow: hidden;
	text-align: center;

	@include media-xs {
		justify-content: space-between;
	}

	a {
		background: none;
		color: currentColor;
		text-decoration: none;
		border: 1px solid $base-color;
		display: inline-block;
		min-width: 36px;
		height: 36px;
		font-weight: bold;
		line-height: 36px;
		border-radius: 3px;

		transition: .2s linear;

		&:hover {
			background: $base-color;
			color: #FFF;
		}
	}

	&__prev,
	&__back,
	&__next {
		a {
			border: 1px solid $base-color;
			display: block;
			overflow: hidden;
			padding: 0 1em;
			position: relative;

			&[data-disabled="true"] {
				opacity: .3;
				pointer-events: none;
			}
		}
	}

	&__prev {
		order: 1;
	}

	&__back {
		order: 2;
		margin: 0 20px;
	}

	&__next {
		order: 3;
	}

	&__numbers {
		display: flex;
		font-size: 1.4rem;
		list-style: none;
		margin: 0 1em;
		order: 2;
		padding: 0;
		@include media-xs {
			display: none;
		}
	}

	&__number {
		display: block;
		list-style: none;
		margin: 0 .5em;

		// 🐲 baserCMSの仕様上 マルチクラスでしかカレントを定義できない
		&--current { // stylelint-disable-line selector-class-pattern
			border-bottom: 1px solid currentColor;

			a:not([href]) {
			}
		}

		a {
			display: block;
			padding: 0 .3em;
			text-align: center;

			&::after {
				display: none;
			}
		}
	}
}
